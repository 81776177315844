const Config = require('~config/default.env').default;
const merge = require('lodash.merge');
import axios from 'axios';

const getArticleCategories = (slug) => {
  return axios.get(`${Config.MS_BJ_PUBLIC_API_URL}/categories`, {
    params: {
      filter: {
        type: 'article',
        ...(slug && { slug }),
      },
    },
  });
};

const getPostHighlightArticles = (slug) => {
  return axios.get(`${Config.MS_BJ_PUBLIC_API_URL}/posts`, {
    params: {
      sort: '-published_at',
      filter: {
        post_type: 'article',
        status: 'publish',
        'tags.name': 'Lilla',
        ...(slug && { 'category.slug': slug }),
      },
      limit: 10,
      skip: 0,
    },
  });
};

const getPostArticles = (slug) => {
  return axios.get(`${Config.MS_BJ_PUBLIC_API_URL}/posts`, {
    params: {
      sort: '-published_at',
      filter: {
        post_type: 'article',
        'tags.name': 'Lilla',
        status: 'publish',
      },
      limit: 10,
      skip: 0,
    },
  });
};

const getDetailArticles = (slug) => {
  return axios.get(`${Config.MS_BJ_PUBLIC_API_URL}/posts/${slug}`, {
    params: {
      section: 'articleDetail',
    },
  });
};

export const postActionArticle = (payload) => {
  return axios.post(`${Config.MS_BJ_PUBLIC_API_URL}/post/${payload.id}/likes`);
};

export const getCommentsArticle = ({ id, params }) => {
  return axios.get(`${Config.MS_BJ_PUBLIC_API_URL}/post/${id}/comments`, {
    params,
  });
};

export const getLoadMoreCommentsArticle = ({ id, params }) => {
  return axios.get(`${Config.MS_BJ_PUBLIC_API_URL}/post/${id}/comments`, {
    params,
  });
};

export const postCommentArticle = (payload) => {
  return axios.post(
    `${Config.MS_BJ_PUBLIC_API_URL}/post/${payload.post_id}/comments`,
    payload,
  );
};

const loadMorePostArticlesRequest = (params) => {
  const defaultParams = {
    sort: '-published_at',
    filter: {
      post_type: 'article',
      status: 'publish',
      ...(params?.slug && { 'tags.slug': params.slug }),
    },
    limit: 10,
    skip: 0,
  };
  const mergeParams = merge(defaultParams, params);
  return axios.get(
    `${Config.MS_BJ_PUBLIC_API_URL}/posts`,

    {
      params: mergeParams,
    },
  );
};

const getTotalPostArticle = (params) => {
  const defaultParams = {
    sort: '-published_at',
    filter: {
      post_type: 'article',
      status: 'publish',
      'tags.name': 'Lilla',
      ...(params?.slug && { 'category.name': params.slug }),
    },
  };
  return axios.get(`${Config.MS_BJ_PUBLIC_API_URL}/posts/count`, {
    params: defaultParams,
  });
};

export const postLikeComment = (id) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/newsfeed/comment/${id}/likes`,
    { emoticon: ':thumb' },
  );
};

export const postLikeCommentReply = (id) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/newsfeed/comment/reply/${id}/likes`,
    { emoticon: ':thumb' },
  );
};

export const reportComment = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/comment/${payload?.id}/report`,
    { reported_type: payload?.reported_type },
  );
};

export const postReplyCommentArticle = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/newsfeed/${payload?.newsfeed_id}/comments/${payload.parent_id}/replies`,
    payload,
  );
};

export const requestDeleteComment = (payload) => {
  return axios.delete(
    `${Config.MS_SOCO_PUBLIC_API_URL}/comments/${payload?.id}/${payload?.reply}`,
  );
};

export const editComment = ({ id, payload }) => {
  return axios.put(`${Config.MS_SOCO_PUBLIC_API_URL}/comments/${id}`, payload);
};

export const editReplyComment = ({ id, payload }) => {
  return axios.put(
    `${Config.MS_SOCO_PUBLIC_API_URL}/comments/${id}/reply`,
    payload,
  );
};

export const Bj = {
  getArticleCategories,
  getPostHighlightArticles,
  getPostArticles,
  getDetailArticles,
  postActionArticle,
  getCommentsArticle,
  postCommentArticle,
  loadMorePostArticlesRequest,
  getTotalPostArticle,
  postLikeComment,
  reportComment,
  postReplyCommentArticle,
  postLikeCommentReply,
  requestDeleteComment,
  editComment,
  editReplyComment,
  getLoadMoreCommentsArticle,
};
