/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect, Suspense } from 'react';
import { StatusBar } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {
  NavigationContainer,
  DefaultTheme,
  getPathFromState,
  getStateFromPath,
} from '@react-navigation/native';
import { navigationRef } from '@web/navigation/RootNavigation';
import interceptorsSetup from './interceptors';
import { Provider } from 'react-redux';
import store from '~store/index';
import Toast from 'react-native-toast-message';
import { toastStyle } from '~assets/styles/modalStyles';
import 'setimmediate';
import 'react-native-gesture-handler';
// navigation
import { PortalProvider, PortalHost } from '@gorhom/portal';
import { toastConfig } from '~config/toastConfig';
import { isWeb, isMobileWeb, isNative } from '~global/screens';
import { removeAsyncData } from '~global/helpers';
import axios from 'axios';
import { initSession } from '~global/sociolla.dm.lib/sociolla-analytics';
import { WebRoutes, Routes } from '~global/web-routes';
import { FbConversion, GoogleTagManager } from '~global/sociolla.dm.lib';
import CustomErrorHandler from '~global/utils/custom-error-handler';

const TabNavigator = React.lazy(() => import('@web/navigation/TabNavigator'));
const ModalAddToCart = React.lazy(() =>
  import('@web/components/modals/ModalAddToCart'),
);
const ModalProductAdded = React.lazy(() =>
  import('@web/components/modals/ModalProductAdded'),
);
const ModalOosProduct = React.lazy(() =>
  import('@web/components/modals/ModalOosProduct'),
);
const ModalLoading = React.lazy(() =>
  import('@web/components/modals/ModalLoading'),
);
const ModalConsent = React.lazy(() =>
  import('@web/components/modals/ModalConsent'),
);

const ModalNotifyMeSuccess = React.lazy(() =>
  import('@web/components/modals/ModalNotifyMeSuccess'),
);
const ModalLogin = React.lazy(() =>
  import('@web/components/modals/ModalLogin'),
);
const ModalActionReview = React.lazy(() =>
  import('@web/components/modals/ModalActionReview'),
);

const ModalAddReviewrecentlyBought = React.lazy(() =>
  import('@web/components/modals/ModalAddReviewRecentlyBought'),
);

const ModalReviewReport = React.lazy(() =>
  import('@web/components/modals/ModalReviewReport'),
);
const ModalRequestToDeleteReview = React.lazy(() =>
  import('@web/components/modals/ModalRequestToDeleteReview'),
);
const ModalUserPhotos = React.lazy(() =>
  import('@web/components/reviews/ModalUserPhotos'),
);
const ModalDynamicBanner = React.lazy(() =>
  import('@web/components/modals/ModalDynamicBanner'),
);

const ModalNotifyMe = React.lazy(() =>
  import('@web/components/modals/ModalNotifyMe'),
);

const ModalVerifyAccount = React.lazy(() =>
  import('@web-components/modals/ModalVerifyAccount'),
);

const ModalAddToBagRegistryWarning = React.lazy(() =>
  import('@web/components/modals/ModalAddToBagRegistryWarning'),
);

const ModalAddToBagPreOrderWarning = React.lazy(() =>
  import('@web/components/product-detail/ModalAddToBagPreOrderWarning'),
);

const ModalAddRegistry = React.lazy(() =>
  import('@web/components/modals/ModalAddRegistry'),
);

const ModalEgiftCropPhoto = React.lazy(() =>
  import('@web-components/product-detail/EgiftCropPhoto'),
);

const Config = require('./config/default.env').default;
const $get = require('lodash.get');
const $merge = require('lodash.merge');
const $debounce = require('lodash.debounce');
const $size = require('lodash.size');
const $forEach = require('lodash.foreach');

// define global variable
global.$validate = require('validate.js');
global.$get = $get;
global.$merge = $merge;
global.$debounce = $debounce;
global.$size = $size;
global.$forEach = $forEach;
global.axios = axios;
global.$validate.validators.presence.options = { message: 'belum diisi' };
global.defaultProductImage = require('~assets/img/dummy_product.png');
global.defaultAvatarSource = require('~assets/img/common/default-avatar-soco.png');
global.isWeb = isWeb;
global.isMobileWeb = isMobileWeb;
global.isNative = isNative;
global.features = require('~global/features.json');
global.$errorHandler = new CustomErrorHandler();

async function fetchSSO() {
  axios.defaults.withCredentials = true;
  await axios.get(`${Config.MS_SSO_BROKER_API_URL}`);
}

const NavigationTheme = {
  ...DefaultTheme,
  colors: { ...DefaultTheme.colors },
};

const toastProps = {
  visibilityTime: 4000,
  autoHide: true,
  position: isWeb ? 'top' : 'bottom',
  topOffset: isWeb ? 120 : 40,
};

const getCurrentState = (state) => {
  if (state?.routes?.length) {
    const index = state.index || 0;
    if (state.routes[index].state) {
      return getCurrentState(state.routes[index].state);
    } else {
      return state.routes[index];
    }
  }
  return {};
};

const checkPage = (name) => {
  const page = name.split('/');
  const reg = new RegExp('^[0-9]*$');
  if (page.length === 0 || !page) {
    return null;
  }
  if (page.length > 2) {
    const productSlug = page[2].split('-');
    const isProductPage = Boolean(
      productSlug.length > 1 && reg.test(productSlug[0]),
    );
    const isPromoPage = page[1].includes('promo');
    if (page.length === 3 && isProductPage && !isPromoPage) {
      return {
        name: 'ProductDetailScreen',
        params: { slug: page[2], categoryName: page[1] },
      };
    } else if (page.length === 3 && isPromoPage) {
      return {
        name: 'PromoCategoryDetailScreen',
        params: { slug: page[2] },
      };
    } else if (page.indexOf('OrderDetailScreen') > -1) {
      return {
        name: 'OrderDetailScreen',
        params: { idOrder: page[page.length - 1] },
      };
    } else {
      return null;
    }
  } else {
    const names = name.replace('/', '');
    const brandPage = names.split('_');
    const categoryPage = names.split('-');
    const typeCategory = new URLSearchParams(window.location.search).get(
      'typeCategory',
    );
    if (brandPage.length > 1 && reg.test(brandPage[0])) {
      return {
        name: 'BrandDetailScreen',
        params: { slug: names },
      };
    } else if (
      (categoryPage.length > 1 && reg.test(categoryPage[0])) ||
      /for-moms|for-baby-kids/gi.test(names) ||
      typeCategory === 'group'
    ) {
      let params = {
        slug: names,
      };

      if (typeCategory === 'group') {
        params = {
          ...params,
          typeCategory,
        };
      }

      return {
        name: 'CategoryDetailScreen',
        params,
      };
    } else {
      return null;
    }
  }
};

let linking = {
  prefixes: [
    'https://uat-lilla-apps.sociolabs.io',
    'https://lilla.id',
    'lilla://',
  ],
  config: isWeb && !isMobileWeb ? WebRoutes : Routes,
};

const serialize = function (obj) {
  const str = [];
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
};

linking = {
  ...linking,
  getStateFromPath: (path, options) => {
    const page = checkPage(path);
    let newPath = path;
    if (page) {
      const { name, params } = page;
      if (name === 'BrandDetailScreen') {
        const brand_params = Object.fromEntries(
          new URLSearchParams(window.location.search).entries(),
        );
        if (brand_params && Object.keys(brand_params).length) {
          if (params.slug.indexOf('?') > -1) {
            const split = params.slug.split('?');
            params.slug = split[0];
          }
          newPath = `/brand?slug=${params.slug}&${serialize(brand_params)}`;
        } else {
          newPath = `/brand?slug=${params.slug}`;
        }
      }
      if (name === 'ProductDetailScreen') {
        const params = Object.fromEntries(
          new URLSearchParams(window.location.search).entries(),
        );
        if (params && Object.keys(params).length) {
          let slug = page.params.slug.match(/(?:).+?(?=\?)/g);
          if (slug && Array.isArray(slug) && slug.length > 0) {
            slug = page.params.slug.match(/(?:).+?(?=\?)/g)[0];
          } else {
            slug = page.params.slug;
          }
          newPath = `/product?slug=${slug}&categoryName=${
            page.params.categoryName
          }&${serialize(params)}`;
        } else {
          newPath = `/product?slug=${page.params.slug}&categoryName=${page.params.categoryName}`;
        }
      }
      if (name === 'CategoryDetailScreen') {
        const params = Object.fromEntries(
          new URLSearchParams(window.location.search).entries(),
        );
        // (?:\/)([^#]+)(?=#*)
        const catUrl = path.match(/(?:\/).+?(?=\?)/g);
        let slug = catUrl?.[0] ? catUrl[0] : path;
        if (slug?.indexOf('/') == 0) {
          slug = slug.split('/')[1];
          if (!catUrl?.[0]) {
            slug = slug.replace('?', '');
          }
        }
        params.slug = slug;
        newPath = `/category?${serialize(params)}`;
      }
      if (name === 'OrderDetailScreen') {
        newPath = `/my-orders/${params.idOrder}`;
      }
    }
    return getStateFromPath(newPath, options);
  },
  getPathFromState: (state, config) => {
    //refresh
    const path = getPathFromState(state, config);
    const current = getCurrentState(state);

    if (current.name === 'BrandDetailScreen') {
      const copyParams = JSON.parse(JSON.stringify(current.params));
      delete copyParams.slug;
      if (copyParams && Object.keys(copyParams).length) {
        return `/${current.params.slug}?${serialize(copyParams)}`;
      }
      return `/${current.params.slug}`;
    } else if (current.name === 'ProductDetailScreen') {
      let queryString = '';
      const copyParams = JSON.parse(JSON.stringify(current.params));
      let queryStringStart = false;
      for (const queryName in copyParams) {
        if (!/id|name|slug|categoryName/.test(queryName)) {
          if (!queryStringStart) {
            queryString += '?';
          } else {
            queryString += '&';
          }
          queryString += `${queryName}=${encodeURIComponent(
            copyParams[queryName],
          )}`;
          queryStringStart = true;
        }
      }
      return `/${current.params.categoryName}/${current.params.slug}${queryString}`;
    } else if (current.name === 'CategoryDetailScreen') {
      const copyParams = JSON.parse(JSON.stringify(current.params));
      delete copyParams.slug;
      return `/${current.params.slug}?${serialize(copyParams)}`;
    } else if (current.name === 'ShopByCategoriesScreen') {
      return `/`;
    } else if (current.name === 'ShopByBrandsScreen') {
      return `/brands`;
    } else if (current.name === 'OrderDetailScreen') {
      return `/my-orders/${current.params.idOrder}`;
    }
    return path;
  },
};

const App = (props) => {
  const routeNameRef = useRef();
  const previousRouteRef = useRef();

  useEffect(() => {
    fetchSSO();
    initSession();
  }, []);

  const handleRedirectOtp = (previous, current) => {
    const backPressed = window.historyBack;
    window.historyBack = false;
    if (
      previous == 'OtpVerificationControlScreen' &&
      (current != 'HomeScreen' || previousRouteRef.current?.params?.section) &&
      backPressed
    ) {
      if (previousRouteRef.current?.params?.section) {
        const oldParams = JSON.parse(
          JSON.stringify(previousRouteRef.current.params),
        );
        // navigationRef.current?.setParams({ section: '' });
        navigationRef.current?.reset({
          index: 0,
          routes: [
            { name: 'HomeScreen' },
            {
              name: 'OtpVerificationControlScreen',
              params: { ...oldParams, section: '' },
            },
          ],
        });
      } else {
        navigationRef.current?.reset({
          index: 0,
          routes: [{ name: 'HomeScreen' }],
        });
      }
    }
  };

  interceptorsSetup();
  return (
    <>
      <StatusBar
        translucent={true}
        backgroundColor={'transparent'}
        barStyle={'dark-content'}
      />

      <Provider store={store}>
        <PortalProvider>
          <SafeAreaProvider>
            <Suspense fallback={<></>}>
              <NavigationContainer
                theme={NavigationTheme}
                ref={navigationRef}
                linking={linking}
                onReady={() => {
                  removeAsyncData('@response_interceptors');
                  // routeNameRef.current = navigationRef.current.getCurrentRoute().name;
                  previousRouteRef.current =
                    navigationRef.current.getCurrentRoute();
                  // Analytics.setCurrentScreen(routeNameRef.current)
                  global.currentRouteName = routeNameRef.current;
                  //logCurrentScreen(routeNameRef.current);
                }}
                onStateChange={() => {
                  removeAsyncData('@response_interceptors');
                  const previousRouteName = routeNameRef.current;
                  const currentRouteName =
                    navigationRef.current.getCurrentRoute().name;
                  global.previousRouteName = previousRouteName;
                  global.currentRouteName = currentRouteName;

                  if (previousRouteName !== currentRouteName) {
                    handleRedirectOtp(previousRouteName, currentRouteName);
                    FbConversion.fbTracking({
                      name: 'PageView',
                    });
                    GoogleTagManager.pageTracking(currentRouteName);
                  }
                  routeNameRef.current = currentRouteName;
                  previousRouteRef.current =
                    navigationRef.current.getCurrentRoute();
                }}>
                <TabNavigator></TabNavigator>

                {/* on web this modals immediately rendered, need adjustment */}
                <ModalActionReview navigation={navigationRef} />
                <ModalReviewReport />
                <ModalRequestToDeleteReview />
                <ModalUserPhotos />
                <ModalUserPhotos />
                <ModalDynamicBanner />
                <ModalAddToCart />
                <ModalProductAdded />
                <ModalOosProduct />
                <ModalLoading />
                <ModalConsent />
                <ModalAddReviewrecentlyBought navigation={navigationRef} />
                <ModalNotifyMeSuccess navigation={navigationRef} />
                <ModalNotifyMe />
                <ModalAddToBagRegistryWarning navigation={navigationRef} />
                <ModalLogin navigation={navigationRef} />
                <ModalAddToBagPreOrderWarning navigation={navigationRef} />
                <ModalAddRegistry navigation={navigationRef} />
                <ModalEgiftCropPhoto navigation={navigationRef} />
                <ModalVerifyAccount navigation={navigationRef} />
                <PortalHost name="additional_menu_host" />
              </NavigationContainer>
            </Suspense>
            <PortalHost name="dropdown_host" />
            <PortalHost name="common_modal" />
            <PortalHost name="modalize_host" />
            <PortalHost name="modal_host" />
            {isMobileWeb && <PortalHost name="sticky_addtobag" />}
          </SafeAreaProvider>
        </PortalProvider>
      </Provider>
      <Toast
        {...toastProps}
        config={toastConfig}
        ref={(ref) => Toast.setRef(ref)}
        style={toastStyle}
      />
    </>
  );
};

export default App;
