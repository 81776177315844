const Config = require('~config/default.env').default;
import axios from 'axios';

const getSimiliarProducts = (params) => {
  return axios.get(`${Config.MS_ANALYTICS_API_URL}/product/similar`, {
    params,
  });
};

const setSessionMe = (payload) => {
  return axios.post(`${Config.MS_ANALYTICS_API_URL}/sessions/me`, payload);
};

const postSessionMe = ({ guild, payload }) => {
  return axios.post(
    `${Config.MS_ANALYTICS_API_URL}/sessions/me/${guild}`,
    payload,
  );
};

export const Analytics = {
  getSimiliarProducts,
  setSessionMe,
  postSessionMe,
};
