import axios from 'axios';
const Config = require('~config/default.env').default;

const getAddress = () => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/users/me/addresses`)
}

const createAddress = (payload) =>
  axios.post(`${Config.MS_SOCO_PUBLIC_API_URL}/addresses`, payload);

const getSingleAddress = (id) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/addresses/${id}`);
};

const updateAddress = ({ id, payload }) =>
  axios.put(`${Config.MS_SOCO_PUBLIC_API_URL}/addresses/${id}`, payload);

const deleteAddress = ({ id }) =>
  axios
    .delete(`${Config.MS_SOCO_PUBLIC_API_URL}/addresses/${id}`)
    .catch(console.log);

export default {
  getAddress,
  createAddress,
  updateAddress,
  deleteAddress,
  getSingleAddress,
};
